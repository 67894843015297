import { Box, Button, Collapse,Divider, IconButton, Paper, Typography } from '@mui/material'
import React, { useState } from 'react'
import './AboutMyself.scss'
import { Add } from '@mui/icons-material'
import EducationList from './EducationList'
import RemoveIcon from '@mui/icons-material/Remove';

const AboutMyself = () => {
  const educationList = EducationList(); 
  const [open, setOpen] = useState({
    0:true
  }); 
  const handleClick = (id) => {
    setOpen((prevState) => ({ [id]: !prevState[id] }));
  };
  const downloadFile = () => {
    window.location.href = "/documents/ram_resume_2023.docx"
  }
  return (
    <Box className='about_section' id='about_section'>
      <Paper className='about_section_content'>
        <Box className='description_section'>
          <Typography className='description_section_label' variant='h2'>About Myself</Typography>
          <Typography className='description_section_text'>I am a fullstack web Developer with more than four years of experience. recognized as a practical and effective developer, experienced in leading cross-functional teams in a time-pressured setting to complete projects on schedule and within budget.</Typography>
        </Box>
        <Divider/>
        <Box className='eduction_section'>
          <Typography className='eduction_section_header'  variant='h4'>Education</Typography>
          {
          educationList.map((item,index)=>{
            return (
              <Box className='eduction_section_content' key={index}>
                <Box className='eduction_section_content_header'>
                  <Typography>{item.degreeName}</Typography>
                  <Typography>{item.period}</Typography>
                  {open[index] ?  <IconButton onClick={()=>handleClick(index)}><RemoveIcon/></IconButton> : <IconButton  onClick={()=>handleClick(index)}><Add/></IconButton>}
                </Box>
                <Collapse in={open[index]} >
                  <Box className='eduction_section_content_body'>
                    <Box className='body_section_left'>
                      <Box className='first_line'>
                        <Typography variant='h5' sx={{lineHeight: 1}}>{item.degreeName}
                          <br/><span style={{fontSize:'0.8rem'}}>({item.stream})</span>
                        </Typography>
                        <Typography >{item.institute} <br/>{item.location}</Typography>
                      </Box>
                      <Divider/>
                      <Typography>{item.description}
                        <span style={{marginLeft:'1rem'}}></span>
                      </Typography>
                    </Box>
                    <Box className='body_section_right'>
                      <img src={`/images/${item.icon}`} alt='logo_img'/>
                    </Box>
                  </Box>
                  
                </Collapse>
              </Box>

            )
          })
        }
          <Box className='resume_download'>
            <Button color='inherit' onClick={downloadFile}>download resume</Button>
          </Box>
        </Box>
      </Paper>
    </Box>
  )
}

export default AboutMyself
