import { Box, Paper,FormControl, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
// import axios from 'axios';
import DatePicker from 'react-date-picker';
import { billDataContext, formHandlerContext } from './BillGenerator';

const ShopInfo = () => {
    const {billData,setBillData} = useContext(billDataContext);
    const {register,errors,clearErrors} = useContext(formHandlerContext);
    const inputChangeHandler =(e)=>{
        setBillData((pre)=>{
            return {...pre,[e.target.name]:e.target.value}
        })
    }
  return (
    <Paper className="bill_info" sx={{width:'45%'}}>
        <Box className='section-header'>
            <Typography className='section-title'>Shop Info</Typography>
        </Box>
        <Box className='section-body' sx={{display:'flex',flexDirection:'column',gap:'0.25rem'}}>
            <Box className="bill_info_row"> 
                <Typography className='label' component='h7'>Name :</Typography>
                <TextField 
                    sx={{width:'75%'}}
                    size='small'
                    autoComplete='off'
                    value={billData.shopName}
                    id="shopName"
                    name="shopName"
                    onChange={inputChangeHandler}
                    InputProps={{
                        inputProps:{
                            style:{
                                height:'0.6rem',
                                textTransform:'uppercase'                             
                            },
                          ...register('shopName',{
                            required:'Empty Not Allowed',
                            pattern:{
                                value:/^[a-zA-Z0-9 .$&+,:;=?@#|'<>.^*()%!-]*$/,
                                message:"only alphabets allowed"
                            },
                            minLength:{
                                value:5,
                                message:"minimum 5 digit"
                            },
                            maxLength:{
                                value:50,
                                message:"maximum 50 digit"
                            }
                          })
                        }
                      }}
                      error={errors.shopName}
                />
            </Box>
            <Box className="bill_info_row">
                <Typography className='label' component='h7'>Address : </Typography>
                <TextField 
                    sx={{width:'75%'}}
                    size='small'
                    autoComplete='off'
                    value={billData.shopAddress}
                    id="shopAddress"
                    name="shopAddress"
                    onChange={inputChangeHandler}
                    InputProps={{
                        inputProps:{
                            style:{
                                height:'0.6rem',
                                textTransform:'uppercase'                             
                            },
                            ...register('shopAddress',{
                                required:'required'
                            })
                            // min: 1, 
                            // max: billInfoData.billId
                        }
                        
                    }}
                    error={errors.shopAddress}
                />
            </Box>
            <Box className="bill_info_row_group">
                <Box className="bill_info_content"> 
                    <Typography className='label' component='h7'> Phone :</Typography>
                    <TextField 
                        sx={{width:'59%'}}
                        size='small'
                        type='number'
                        name='shopPhone'
                        id='shopPhone'
                        value={billData.shopPhone}
                        onChange={inputChangeHandler}
                        InputProps={{
                            inputProps:{
                                style:{
                                    height:'0.6rem',                            
                                },
                                ...register('shopPhone',{
                                        required:'required',
                                        pattern:{
                                            value:/[1-9]{1}[0-9]{9}/,
                                            message:"only numeric : not starting with 0"
                                        },
                                        minLength:{
                                            value:10,
                                            message:"contact should be of 10 digit"
                                        },
                                        maxLength:{
                                            value:10,
                                            message:"contact should be of 10 digit"
                                        }
                                    }
                                )
                            }
                        }} 
                        error={errors.shopPhone}   
                    />
                    
                </Box>
                <Box className="bill_info_content"> 
                    <Typography className='label' component='h7'>GstIn :</Typography>
                    <TextField 
                        sx={{width:'80%'}}
                        size='small'
                        // autoComplete='off'
                        value={billData.shopGstIn}
                        id="shopGstIn"
                        name="shopGstIn"
                        onChange={inputChangeHandler}
                        InputProps={{
                            inputProps:{
                                style:{
                                    height:'0.6rem',
                                    textTransform:'uppercase'                             
                                },
                                ...register('shopGstIn',{
                                    required:'required',
                                    pattern:{
                                        value:/[a-zA-Z0-9]{15}/,
                                        message:"only numeric : not starting with 0"
                                    },
                                    minLength:{
                                        value:15,
                                        message:"gstin should be of 15 digit"
                                    },
                                    maxLength:{
                                        value:15,
                                        message:"gstin should be of 10 digit"
                                    }
                                })
                            }
                        }}  

                        error={errors.shopGstIn}    
                    /> 
                </Box>
            </Box>
        </Box>  
    </Paper>
  )
}

export default ShopInfo
