import React from 'react'
import './App.css'
import Dashboard from './pages/Dashboard';
import { Route, Routes } from 'react-router-dom';
import BillGenerator from './pages/products/bill-generator/BillGenerator';

const App = () => {
	
	return (
 
      
	<Routes>
      <Route path='/'>
        <Route index element={<Dashboard/>} />
      </Route>
      <Route path='/my-works'>
        <Route path="bill-generator" element={<BillGenerator/>} />
      </Route>
    </Routes>
	)
}

export default App;
