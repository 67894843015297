import { Box, IconButton, Link, Toolbar, Tooltip, Typography } from '@mui/material'
import React from 'react'
import MenuIcon from '@mui/icons-material/Menu'

const Footer = () => {
  return (
    <Box className='footer'
      sx={{background:'#006064',position:'fixed',bottom:'0',width:'100%',
      }}>
      <Toolbar sx={{minHeight:'26px!important',display: { xs: 'none', md: 'flex' }}}>        
        {/* <Tooltip title='open sidebar'>
          <IconButton sx={{p:'0'}} onClick={()=>setDrawerOpen(true)}>
            <MenuIcon />
          </IconButton>
        </Tooltip> */}
          
        <Typography variant="body2" color="white" align="center" width='100%'>
          {'Copyright © '}
          <Link color="inherit" href="#">
            SSKINFOTECH@Developed By R.P.Singh
          </Link>
        </Typography>
      </Toolbar>
  </Box>
  )
}

export default Footer
