import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import React, { useContext } from 'react'
import { Close } from '@mui/icons-material';
import { dialogContext } from './BillGenerator';

const SuccessDialog = (props) => {
    const {successDialog,onCloseSuccessDialog} = useContext(dialogContext);
  return (
    <Dialog open={successDialog}  sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "15%", // Set your width here
          },
        },
      }} >
        <DialogContent className='custom_dialog'>
                {/* <Typography color={'red'}>{errorMessage}</Typography> */}
                
                <Box className="dialog_header" sx={{backgroundColor:'#009688',}}>
                    <Typography className='header_title' sx={{color:'#ffffff',pl:1,fontWeight:800,fontSize:'0.9rem'}}>Result</Typography>
                </Box>
                    
                
                <Box className='custom_dialog_content'  sx={{}} >
                  <Box textAlign={'center'}>
                    
                    <Typography sx={{color:'#116a06'}}>!  Done  !</Typography>
                    
                  </Box>
                  {/* <Box>
                    <Typography>Solution </Typography>
                    <Typography>{errorMessage}</Typography>
                  </Box> */}
                </Box>
        </DialogContent>
        <DialogActions  className='' sx={{justifyContent:' center',padding:0.5}}>
                <Button sx={{minWidth:'35px',padding:0}} className=''  variant='contained' color='error' size={'small'} onClick={()=>onCloseSuccessDialog()}>OK</Button>
        </DialogActions>
    </Dialog>
  )
}

export default SuccessDialog
