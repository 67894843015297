import { Box, Collapse, Divider, IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'
import './ProfessionalExperience.scss'
import { Add } from '@mui/icons-material'
import RemoveIcon from '@mui/icons-material/Remove';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LaunchIcon from '@mui/icons-material/Launch';
import ExperienceList from './ExperienceList';

const ProfessionalExperience = () => {
  const [open, setOpen] = useState({
    0:true
  });
  const experienceList = ExperienceList();
  const handleClick = (id) => {
    setOpen((prevState) => ({ [id]: !prevState[id] }));
  };
  return (
    <Box className='experience'>
      <Box className='experience_header'>
        <Typography variant='h2'>Professional Experience</Typography>
      </Box>
      <Divider/>
      <Box className='experience_body'>
        {
          experienceList.map((item,index)=>{
            return (
              <Box className='experience_content' key={index}>
                <Box className='experience_content_header'>
                  <Typography>{item.companyName}</Typography>
                  <Typography>{item.period}</Typography>
                  {open[index] ?  <IconButton onClick={()=>handleClick(index)}><RemoveIcon/></IconButton> : <IconButton  onClick={()=>handleClick(index)}><Add/></IconButton>}
                </Box>
                <Collapse in={open[index]} >
                  <Box className='collapse_box'>
                    <Box className='collapse_box_header'>
                      <IconButton><LocationOnIcon color='primary'/>{item.location}</IconButton>
                      <IconButton><LaunchIcon color='secondary'/>{item.link}</IconButton>
                    </Box>
                    <Box className='collapse_box_body'>
                      <Box className='collapse_box_left'>
                        <Typography>{item.description}</Typography>
                        <Box className='collapse_box_footer'>
                          {item.keywords.map((keyword,i)=>{
                            return (
                              <Box className='collapse_box_footer_item' key={i}>
                                  {keyword}
                              </Box>
                            )
                          })}
                        </Box>
                      </Box>
                      <Box className='collapse_box_right'>
                        <img src={`/images/${item.icon}`} alt='company_icon'/>
                      </Box>
                    </Box>
                  </Box>
                </Collapse>
              </Box>

            )
          })
        }
        
      </Box>
    </Box>
  )
}

export default ProfessionalExperience
