import { AppBar, Box, Button,IconButton,ListItemIcon,ListItemText,Menu,MenuItem,Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './NavBar.scss'
import { Power3 } from 'gsap'
import MenuIcon from '@mui/icons-material/Menu';
import { Book, Home } from '@mui/icons-material';

const NavBar = ({timeline}) => {
  // let tl = gsap.timeline();
  useEffect(()=>{
    timeline.from('.rpsingh_logo',2,{
      x:100,
      opacity:0,
    })
    .from('.rpsingh_navbar_menu',2, {
        opacity: 0,
        y: -200,
        // x:-500,
        // stagger: {
        //     amount: .4
        // },
        ease: Power3.easeOut
    },"-=1");
   
  },[]);
  const scrollTo = (id)=>{
    var element = document.getElementById(id);
    element.scrollIntoView({behavior:"smooth"});
  }
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar className='rpsingh_navBar' sx={{background:'transparent'}}>
        <Box className='rpsingh_logo'>
            <Typography className='text_logo'>rpsingh</Typography>
        </Box>
        <Box className='rpsingh_navbar_menu'>
            <Button>Home</Button>
            <Button onClick={()=>scrollTo('about_section')}>About</Button>
            <Button onClick={()=>scrollTo('portfolio')}>Work</Button>
            <Button >Tutorials</Button>
            <Button onClick={()=>scrollTo('rpsingh_footer')}>Contact me</Button>
        </Box>
        <Box className='hamburger'>
          <IconButton 
            sx={{color:'#ffffff'}}
            id="basic-button"
            // aria-controls={open ? 'basic-menu' : undefined}
            // aria-haspopup="true"
            // aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <MenuIcon color='inherit'/>
          </IconButton>
          <Menu
            // id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            // MenuListProps={{
            //   'aria-labelledby': 'basic-button',
            // }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <Home fontSize="small" />
              </ListItemIcon>
              <ListItemText>Home</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <Book fontSize="small" />
              </ListItemIcon>
              <ListItemText>Tutorials</ListItemText>
            </MenuItem>
            {/* <MenuItem onClick={handleClose}>Home</MenuItem> */}
            {/* <MenuItem onClick={()=>handleClose('about_section')}>About</MenuItem>
            <MenuItem onClick={()=>handleClose('portfolio')}>Work</MenuItem> */}
            {/* <MenuItem onClick={handleClose}>Tutorials</MenuItem> */}
            {/* <MenuItem onClick={()=>handleClose('rpsingh_footer')}>Contact me</MenuItem> */}
          </Menu>
        </Box>
    </AppBar>
  )
}

export default NavBar
