import { Autocomplete, Box, InputAdornment, Paper, TextField, Typography } from '@mui/material'
import React, { useContext } from 'react'
import DatePicker from 'react-date-picker'
import { billDataContext, formHandlerContext } from './BillGenerator';

const InvoiceInfo = () => {
    const {billData,setBillData} = useContext(billDataContext);
    const {register,errors,clearErrors} = useContext(formHandlerContext);
    const inputChangeHandler =(e)=>{
        setBillData((pre)=>{
            return {...pre,[e.target.name]:e.target.value}
        })
    }
    const billingDateChangeHandler =(value)=>{
        setBillData((pre)=>{
            return {...pre,billingDate:value}
        })
    }
  return (
    <Paper className="bill_info">
      <Box className='section-header'>
          <Typography className='section-title'>Invoice Info</Typography>
      </Box>
      <Box className='section-body' sx={{display:'flex',flexDirection:'column',gap:'0.25rem'}}>
      <Box className="bill_info_row">
            <Typography className='label' component='h7' sx={{width:'38%!important'}}>Invoice No. : </Typography>
            <TextField 
                sx={{width:'58%'}}
                size='small'
                autoComplete='off'
                value={billData.billNumber}
                id="billNumber"
                name="billNumber"
                onChange={inputChangeHandler}
                InputProps={{
                    // startAdornment: (
                    //     <InputAdornment position="start" sx={{textTransform:'uppercase'}}>
                    //       {billSeries}
                    //     </InputAdornment>
                    //   ),
                    inputProps:{
                        style:{
                            height:'0.6rem',
                            textTransform:'uppercase'                             
                        },
                        ...register('billNumber',{
                            required:'required',
                            pattern:{
                                value:/[a-zA-Z0-9]/,
                                message:"only numeric : not starting with 0"
                            },
                            minLength:{
                                value:1,
                                message:"minimum should be of 15 digit"
                            },
                            maxLength:{
                                value:15,
                                message:"maximum should be of 10 digit"
                            }
                        })
                    }
                }}
                error={errors.billNumber}
            />
        </Box>
        <Box className="bill_info_row"> 
            <Typography className='label' component='h7' sx={{width:'38%!important'}}>Billing Date :</Typography>
            <Box className="customDatePickerWidth">
                <DatePicker
                    disableCalendar
                    clearIcon={null}
                    value={billData.billingDate}                       
                    id='billingDate'
                    name='billingDate'
                    onChange={billingDateChangeHandler}
                    minDate={new Date(new Date().setDate(new Date().getDate()-365))}
                    maxDate={new Date(new Date().setDate(new Date().getDate()+365))}
                    // maxDate={new Date("31-03-2024")}
                    format="dd/MM/y"
                    inputProps={{
                        style:{
                            width:'100%'
                        },
                        // ...register('billingDate',{
                        //     required:'required',
                        // })
                    }}
                    // error={errors.billingDate}
                />
            </Box>
        </Box> 
        <Box className="bill_info_row"> 
            <Typography className='label' component='h7' sx={{width:'38%!important'}}>due Date :</Typography>
            <Box className="customDatePickerWidth">
                <DatePicker
                    disableCalendar
                    clearIcon={null}
                    value={billData.dueDate}                       
                    id='dueDate'
                    name='dueDate'
                    onChange={inputChangeHandler}
                    minDate={new Date(new Date().setDate(new Date().getDate()-365))}
                    maxDate={new Date()}
                    // maxDate={new Date("31-03-2024")}
                    format="dd/MM/y"
                    InputProps={{
                        inputProps:{
                            style:{
                                width:'100%'
                            },
                            // ...register('dueDate',{
                            //     required:'required',
                            // })
                        }
                    }}
                    // error={errors.dueDate}
                />
            </Box>
        </Box> 
      </Box>
    </Paper>
  )
}

export default InvoiceInfo
