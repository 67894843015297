import { Box, Button, Dialog, DialogContent, DialogTitle, FormControl, IconButton, MenuItem, Select, TextField, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import './dialog.scss'
import { Close } from '@mui/icons-material';
import { billDataContext, dialogContext, formHandlerContext } from './BillGenerator';
import { useForm } from 'react-hook-form';

const PaymentDialog = (props) => {
    const {openPaymentDialog,setOpenPaymentDialog} = props;
    const {billData,setBillData} = useContext(billDataContext);
    const {finalBillSubmit} = useContext(dialogContext);
    const {reset} = useContext(formHandlerContext);
   
    const{register,handleSubmit,formState:{errors},clearErrors}= useForm();
    // const [billData,setbillData] = useState({
    //     billType:'',
    //     saleType:'',
    //     paymentMode:'',
    //     paidAmount:'',
    //     dueAmount:'',
    // })

    const inputChangeHandler=(e)=>{
        // console.log(e.target.field);
        setBillData((pre)=>{
            return {...pre,[e.target.name]:e.target.value}
        })
    }

    const onCancel = ()=>{
        // reset();
        // setSubmitBill(false);
        setOpenPaymentDialog(false);
    }
    useEffect(()=>{
        if (billData.paidAmount<=billData.finalBillAmount) {
            var dueAmount = parseFloat(billData.finalBillAmount - billData.paidAmount);
            setBillData((pre)=>{
                return {...pre,dueAmount:dueAmount}
            }); 
        }else{
            setBillData((pre)=>{
                return {...pre,paidAmount:billData.finalBillAmount,dueAmount:0}
            });
        }
    },[billData.paidAmount])
  return (
    <Dialog open={openPaymentDialog} fullWidth>
        {/* <DialogTitle padding={'0px 15px!important'}>
            <Box sx={{display:'flex'}}>
                <Typography sx={{flex:1}}>Money Receipt Entry</Typography>
                <Button variant='contained' color='error' size='small' onClick={()=>setOpenPaymentDialog(false)}>CLOSE</Button>
            </Box>
        </DialogTitle> */}
        <DialogContent className='custom_dialog'>
            <Box className='dialog_header'>
                <Typography className='header_title'>Money Receipt Entry</Typography>
                <IconButton className='action_button' variant='outlined' color='error' size='small' onClick={()=>setOpenPaymentDialog(false)}><Close/></IconButton>
            </Box>
            <Box className='custom_dialog_content' component={'form'} onSubmit={handleSubmit(finalBillSubmit)}>
                {/* billType..gst/challan,saleType:credit/cash,paymentMode:cash/online,billAmount,paidAmount. */}
                <Box className='content_row_group'>
                    <Box className='input_row'>
                        <Typography className='label'  component={'h7'}>Bill Type</Typography>
                        <FormControl fullWidth>
                            <Select
                                sx={{minHeight:'0px',height:'1.6rem',fontSize:'small'}}
                                id="billType"
                                size='small'
                                name='billType'
                                value={billData.billType}
                                onChange={inputChangeHandler}
                                displayEmpty
                                type='search' 
                                inputProps={{
                                    ...register('billType',{
                                        required:'required'
                                    })
                                }}
                                error={errors.billType}  
                            >
                                <MenuItem value="gst invoice">GST INVOICE</MenuItem>
                                <MenuItem value="challan">CHALLAN</MenuItem>
                                <MenuItem value="rough estimate">ROUGH ESTIMATE</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box className='input_row'>
                        <Typography className='label' component={'h7'}>Sale Type</Typography>
                        <FormControl fullWidth>
                            <Select
                                sx={{minHeight:'0px',height:'1.6rem',fontSize:'small'}}
                                id="saleType"
                                size='small'
                                name='saleType'
                                value={billData.saleType}
                                onChange={inputChangeHandler}
                                displayEmpty
                                type='search' 
                                inputProps={{
                                    ...register('saleType',{
                                        required:'required'
                                    })
                                }}
                                error={errors.saleType}                         
                            >
                                <MenuItem value="credit">CREDIT</MenuItem>
                                <MenuItem value="cash">CASH</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Box className='content_row_group'>
                    <Box className='input_row'>
                        <Typography className='label'  component={'h7'}>Payment Mode</Typography>
                        <FormControl fullWidth>
                            <Select
                                sx={{minHeight:'0px',height:'1.6rem',fontSize:'small'}}
                                id="paymentMode"
                                size='small'
                                name='paymentMode'
                                value={billData.paymentMode}
                                onChange={inputChangeHandler}
                                displayEmpty
                                inputProps={{
                                    ...register('paymentMode',{
                                        required:'required'
                                    })
                                }}
                                error={errors.paymentMode}                                 
                            >
                                <MenuItem value="cheque">CHEQUE</MenuItem>
                                <MenuItem value="cash">CASH</MenuItem>
                                <MenuItem value="upi">UPI</MenuItem>
                                <MenuItem value="bank">BANK</MenuItem>
                                <MenuItem value="net banking">Net Banking</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                    <Box className='input_row'>
                        <Typography className='label' component={'h7'}>Bill Amount</Typography>
                        <TextField
                            type='number'
                            fullWidth
                            size='small'
                            id='finalBillAmount'
                            name='finalBillAmount'
                            value={billData.finalBillAmount}
                            InputProps={{
                                style:{
                                    height:'1.6rem',
                                }
                            }}
                        />
                    </Box>
                </Box>
                <Box className='content_row_group'>
                    <Box className='input_row'>
                        <Typography className='label'  component={'h7'}>Paid Amount</Typography>
                        <TextField
                            fullWidth
                            type='number'
                            size='small'
                            id='paidAmount'
                            name='paidAmount'
                            value={billData.paidAmount}
                            onChange={inputChangeHandler}
                            InputProps={{
                                style:{
                                    height:'1.6rem',

                                },
                                inputProps:{min: 0,
                                    ...register('paidAmount',{
                                        required:'required'
                                    })
                                   
                                }
                            }}
                            error={errors.paidAmount}
                        />   
                    </Box>
                    <Box className='input_row'>
                        <Typography className='label' component={'h7'}>Due Amount</Typography>
                        <TextField
                        fullWidth
                            type='number'
                            size='small'
                            value={billData.dueAmount}
                            InputProps={{
                                style:{
                                    height:'1.6rem',

                                },
                            }}
                        />       
                    </Box>
                </Box>
                <Box className='bottom_section'>
                    <Box className='action_section'>
                        <Box className='action_buttons'>
                            <Button variant='contained' size='small' color='success' 
                                type='submit'
                                // onClick={onSubmitLedgerEntries}
                                // disabled={ledgerEntryData.creditedAmount>0 ? false:true}
                            >
                                Accept
                            </Button>
                            <Button variant='contained' size='small' color='error'
                                onClick={()=>onCancel()}
                            >Cancel</Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </DialogContent>
    </Dialog>
  )
}
export default PaymentDialog
