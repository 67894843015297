import { Box, IconButton, MenuItem, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
// import { PurchaseDataContext, SaleDataContext } from './SaleBill'
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { billDataContext } from './BillGenerator';
import DatePicker from 'react-date-picker';
import { Delete } from '@mui/icons-material';
// import dayjs from 'dayjs';
// import { SelectedProductContext } from './SaleBill';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DateField, LocalizationProvider } from '@mui/x-date-pickers';

const AddedMeds = () => {
    const{billData,setBillData,productInitialState,selectedProduct,setSelectedProduct} = useContext(billDataContext);
    
    const deleteProduct =(productIndex)=>{
        let tmpArray = [...billData.addedProducts];
        tmpArray.splice(productIndex,1);//splice() modifies the array in-place instead of creating a new array. What this means is that you're setting state to the same array reference (even though its contents have changed), and React doesn't see that as an update to state.
        setBillData((pre)=>{
            return {...pre,addedProducts:tmpArray}
        })
    }

    const onSelectProductToEdit =(productIndex)=>{
        console.log('product to edit',billData.addedProducts[productIndex]);
        setSelectedProduct(billData.addedProducts[productIndex]);
        deleteProduct(productIndex);
    }
    const onSelectProductToDelete =(productIndex)=>{
        console.log('product to Delete',billData.addedProducts[productIndex]);
        deleteProduct(productIndex);
    }
  return (
    <Box className="meds-selected" sx={{
        
    }}>
        <Table size='small' sx={{width: 'max-content'}} stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>S.NO.</TableCell>
                    <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>Product</TableCell>
                    <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>Batch</TableCell>
                    <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>Price</TableCell>
                    {/* <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>B.Rate</TableCell> */}
                    {/* <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>Scheme</TableCell>
                    <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>scheme(%)</TableCell>
                    <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>schemeDiscount</TableCell>
                    <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>schemeReducedRate</TableCell> */}
                    <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>Discount</TableCell>
                    <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>discountAmount</TableCell>
                    {/* <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>discountReducedRate</TableCell> */}
                    <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>Quantity</TableCell>
                    <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>productAmount</TableCell>
                    <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>GST</TableCell>
                    <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>taxedAmount</TableCell>
                    <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>final Amt.</TableCell>
                    <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>netRate</TableCell>
                    {/* <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>Amount</TableCell> */}
                    <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey',}}>Expiry</TableCell>
                    <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
               {billData.addedProducts.map((item,index)=>{
                
                 return (
                    <TableRow key={index}  
                    // sx={{cursor:'pointer'}} data-my-value={index}
                    // onClick={selectMedsForEdit}
                    >
                        
                        <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>{index+1}</TableCell>
                        <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>{item.productName}</TableCell>
                        <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>{item.productBatch}</TableCell>
                        {/* <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>{item.mrp}</TableCell> */}
                        <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>{item.price}</TableCell>
                        {/* <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>{item.schemeA+"+"+item.schemeB}</TableCell> */}
                        {/* <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>{parseFloat(item.schemeDiscountPercentage).toFixed(2)}</TableCell> */}
                        {/* <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>{parseFloat(item.schemeDiscount).toFixed(2)}</TableCell> */}
                        {/* <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>{parseFloat(item.schemeReducedRate).toFixed(2)}</TableCell> */}
                        <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>{item.discountPercentage}</TableCell>
                        <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>{parseFloat(item.discountAmount).toFixed(2)}</TableCell>
                        {/* <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>{parseFloat(item.discountReducedRate).toFixed(2)}</TableCell> */}
                        <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>{item.quantity}</TableCell>
                        <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>{parseFloat(item.productAmountAfterDiscount).toFixed(2)}</TableCell>
                        <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>{item.taxPercentage}</TableCell>
                        <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>{parseFloat(item.taxedAmount).toFixed(2)}</TableCell>
                        <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>{parseFloat(item.finalProductAmount).toFixed(2)}</TableCell>
                        <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>{parseFloat(item.netRate).toFixed(2)}</TableCell>
                        {/* <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>{parseFloat(item.pureProductAmount).toFixed(2)}</TableCell> */}
                        <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey'}}>
                            <Box className= "datePickerCustom">
                                <DatePicker
                                disableCalendar
                                clearIcon={null}
                                
                                value={item.expiryDate}
                               
                                minDate={new Date(new Date().setDate(new Date().getDate()+90))}
                                // monthPlaceholder='MM'
                                // yearPlaceholder='YYYY'
                                format="MM/y"
                                disabled
                                />   
                            </Box>
                        </TableCell>
                        <TableCell sx={{textAlign:'center',borderLeft:'1px solid lightGrey',borderRight:'1px solid lightGrey',padding:'2px 0px'}}>
                            <IconButton size='small' onClick={()=>onSelectProductToEdit(index)} ><EditIcon color='warning'/></IconButton>
                            <IconButton size='small' onClick={()=>onSelectProductToDelete(index)} ><Delete color='error'/></IconButton>
                        </TableCell>
                    </TableRow> 
                     )
               })}
            </TableBody>
        </Table>
    </Box>
  )
}

export default AddedMeds