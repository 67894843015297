const EducationList = () => {
    const menu = [
        {
            degreeName:'B.Tech',
            stream:'Elcetronics & Communication Eng.',
            period:'2012 - 2016',
            institute:'FET,GURUKUL KANGRI UNIVERSITY',
            location:'Haridwar, (UK)',
            link:'gkv.ac.in/fet',
            description:'I have completed my B.Tech from well renowned institute i.e. Gurukul Kangri University,Haridwar(UK), with good academic career and percentage of marks.',
            icon:'gkv_icon.png'
        },
        {
            degreeName:'InterMediate',
            stream:'PCM Group',
            period:'2009 - 2011',
            institute:'M L Convent School',
            location:'Prayagraj,UttarPradesh',
            link:'mlconvent.com',
            description:'I have completed my senior secondary from M.L.Convent Inter College with good academic career and good marks.',
            icon:'mlc_icon.png'
        },
        {
            degreeName:'High School',
            stream:'Science Group',
            period:'2009 - 2011',
            institute:'M L Convent School',
            location:'Prayagraj,UttarPradesh',
            link:'mlconvent.com',
            description:'I have completed my secondary school from M.L.Convent Inter College with good academic career and good marks.',
            icon:'mlc_icon.png'
        }
    ];
  return menu;
}

export default EducationList
