import { Box, Button, Container } from '@mui/material'
import React, { createContext, useEffect, useRef, useState } from 'react'
import './billgenerator.scss'
import ShopInfo from './ShopInfo'
import BuyerInfo from './BuyerInfo'
import InvoiceInfo from './InvoiceInfo'
import MedsEntry from './MedsEntry'
// import FinalBillDetails from './FinalBillDetails'
import Footer from './Footer'
import { useForm } from 'react-hook-form'
import PaymentDialog from './PaymentDialog'
import ConfirmationDialog from './ConfirmationDialog'
import SuccessDialog from './SuccessDialog'
import NavBar from './NavBar'
// import ViewBillPdf from './pdfview/ViewBillPdf'

const billDataContext = createContext();
const formHandlerContext =createContext();
const dialogContext = createContext();
const BillGenerator = () => {

    const billDataInitialState={
        billNumber:'',
        billingDate:new Date(),
        dueDate:'',
        buyerName:'',
        buyerAddress:'',
        buyerPhone:'',
        buyerGstIn:'',
        shopName:'',
        shopAddress:'',
        shopPhone:'',
        shopGstIn:'',
        addedProducts:[

        ],
        
        totalItems:'',
        totalQuantity:'',
        taxDetails:[

        ],
        finalTaxCalculation:{

        },
        subTotal:'',
        discountAmount:'',
        taxAmount:'',
        finalBillAmount:'',
        // transactionDetails:{
        billType:'',
        saleType:'',
        paymentMode:'',
        paidAmount:'',
        dueAmount:'',

    };
    const [billData,setBillData] = useState(billDataInitialState);
    const{register,handleSubmit,formState:{errors},clearErrors,reset}= useForm();
    const productInitialState = {
        productName:'',
        productBatch:'',
        // productMrp:'',
        price:'',
        quantity:'',
        // baseRate:'',
        // schemeA:'',
        // schemeB:'',
        // schemeDiscount:'',
        pureProductAmount:'',
        discountPercentage:'',
        discountAmount:'',
        productAmountAfterDiscount:'',
        taxPercentage:'',
        taxedAmount:'',//calculated on (baseRate-discountAmount)
        productAmount:'',//(discountReducedRate*quantity)
        finalProductAmount:'',//productAmount+taxedAmount-discountAmount
        expiryDate:'',
        netRate:'',
      }
      const [selectedProduct,setSelectedProduct] = useState(productInitialState);

    /** to trigger submit button which is hided below */
    // const [submitBill,setSubmitBill] = useState(false);
    const ref = useRef(null);
  
      const submitBill =()=>{
        console.log('submit button clicked')
        ref.current.click();
      }
    /** **********************/
    const [openPaymentDialog,setOpenPaymentDialog] = useState(false);
    //this function will called before payment dialog
    const billSubmit =()=>{
        console.log('hello');
        setOpenPaymentDialog(true);
    }
    // this function will be called for final billSubmit and open pdf
    const finalBillSubmit =()=>{
        console.log('final Bill submission');
        setConfirmationDialog(true);
    }
    /****************** */
    const [confirmationDialog,setConfirmationDialog] = useState(false);
    const [successDialog,setSuccessDialog] = useState(false);
    // confirmation Dialog Related
    const onConfirmation =()=>{
        setConfirmationDialog(false);
        if (!billData.billingDate) {
            setBillData((pre)=>{
                return {...pre,billingDate:new Date()}
            })
        }
        setSuccessDialog(true);
    }
    // Successfull Dialog Related
    const [viewBill,setViewBill] = useState(false);
    const onCloseSuccessDialog=()=>{
        setViewBill(true);
        setSuccessDialog(false);
        setOpenPaymentDialog(false);
    }
  return (
    <>
    <NavBar/>
    <Box className="bill_generator_dashboard"> 
        <Container className='content_section' maxWidth='xl' sx={{columnGap:'10px',mt:'1rem'}}>
            <billDataContext.Provider value={{billDataInitialState,billData,setBillData,submitBill,productInitialState,selectedProduct,setSelectedProduct}}>
                <formHandlerContext.Provider value={{register,handleSubmit,errors,clearErrors,reset}}>
                    <Box className="billing_details"  component='form' onSubmit={handleSubmit(billSubmit)}>
                        <ShopInfo/>
                        <BuyerInfo/>
                        <InvoiceInfo/>
                        <Button sx={{display:'none'}} ref={ref} type='submit'>sumit</Button>
                        {/* this button will be triggered by button on finalbillDetail Component because nested form is not allowed */}
                    </Box>
                    <MedsEntry/>
                    {/* <FinalBillDetails /> */}
                    <dialogContext.Provider value={{confirmationDialog,setConfirmationDialog,onConfirmation,successDialog,setSuccessDialog,onCloseSuccessDialog,finalBillSubmit}}>
                        <PaymentDialog openPaymentDialog={openPaymentDialog} setOpenPaymentDialog={setOpenPaymentDialog}/>
                        {/* confirmation Dialog */}
                        <ConfirmationDialog />
                        {/* Success Dialog */}
                        <SuccessDialog/>
                    </dialogContext.Provider>
                    {/* <ViewBillPdf  viewBill={viewBill} setViewBill={setViewBill}/> */}
                </formHandlerContext.Provider>
            </billDataContext.Provider>
        </Container>
        <Footer/>
    </Box>
     
    </>
  )
}

export default BillGenerator
export { billDataContext,formHandlerContext,dialogContext }