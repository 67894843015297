import { Box, Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import React, { useContext } from 'react'
import { Close } from '@mui/icons-material';
import { dialogContext } from './BillGenerator';
const ConfirmationDialog = (props) => {
    const {confirmationDialog,setConfirmationDialog,onConfirmation} = useContext(dialogContext);
    return (
      <Dialog open={confirmationDialog} sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "25%", // Set your width here
            },
          },
        }} >
          <DialogContent className='custom_dialog'>
                  {/* <Typography color={'red'}>{errorMessage}</Typography> */}
                  
                  <Box className="dialog_header">
                      <Typography className='header_title' >Confirmation</Typography>
                  </Box>
                      
                  
                  <Box className='custom_dialog_content'  sx={{}} >
                    <Box textAlign={'center'}>
                      
                      <Typography sx={{color:'#116a06'}}>Do you want to Submit ? Any change Will Not be allowed after submit.</Typography>
                      
                    </Box>
                    {/* <Box>
                      <Typography>Solution </Typography>
                      <Typography>{errorMessage}</Typography>
                    </Box> */}
                  </Box>
          </DialogContent>
          <DialogActions  className='' sx={{justifyContent:' ',padding:0.5}}>
                  <Button  className=''  variant='contained' color='success' size={'small'} onClick={()=>onConfirmation()}>Confirm</Button>
                  <Button  className=''  variant='contained' color='error' size={'small'} onClick={()=>setConfirmationDialog(false)}>Cancel</Button>
          </DialogActions>
      </Dialog>
  )
}

export default ConfirmationDialog
