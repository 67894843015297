import React, { useContext, useEffect, useState } from 'react'
import {Box,Button, Dialog, DialogActions, DialogContent, FormControl, IconButton, InputAdornment, Menu, MenuItem, Paper, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography} from '@mui/material'
import AddIcon from '@mui/icons-material/Add';
// // import SelectMedicine from './SelectMedicine';
// import AddedMeds from './AddedMeds';
import DatePicker from 'react-date-picker';
import { Close } from '@mui/icons-material';
import AddedMeds from './AddedMEds';
import { useForm } from 'react-hook-form';
import { billDataContext } from './BillGenerator';
// import SelectMedicine from './SelectMedicine';
// import ErrorDialog from '../../../components/dialogs/ErrorDialog';
// import NetErrorDialog from '../NetErrorDialog';

const MedsEntry = () => {
  const {billData,setBillData,productInitialState,selectedProduct,setSelectedProduct} = useContext(billDataContext);
  const{register,handleSubmit,formState:{errors},clearErrors}= useForm();
  
  const inputChangeHandler =(e)=>{
    setSelectedProduct((pre)=>{
      return{...pre,[e.target.name]:e.target.value}
    })
  }
  const expiryDateChangeHandler =(value)=>{
    setSelectedProduct((pre)=>{
      return{...pre,'expiryDate':value}
    })
  }
  const addProduct =(e)=>{
    setBillData((pre)=>{
      return {...pre,addedProducts:[...pre.addedProducts,{...selectedProduct}]}
    });
    setSelectedProduct(productInitialState);
  }
  useEffect(()=>{
    if (selectedProduct.price||selectedProduct.quantity||selectedProduct.discountPercentage||selectedProduct.taxPercentage) {
      var taxedAmount = 0;
      var discountAmount =0;
      var finalProductAmount = 0;
      
      var pureProductAmount = parseFloat(selectedProduct.price * selectedProduct.quantity);
      discountAmount = parseFloat((pureProductAmount * selectedProduct.discountPercentage)/100);
      var productAmountAfterDiscount = parseFloat(pureProductAmount - discountAmount);
      taxedAmount = parseFloat((productAmountAfterDiscount*selectedProduct.taxPercentage)/100);
      finalProductAmount = parseFloat(productAmountAfterDiscount + taxedAmount);
      var netRate =  parseFloat(finalProductAmount/selectedProduct.quantity);
      
      setSelectedProduct((pre)=>{
        return { ...pre,
          pureProductAmount:pureProductAmount,
          discountAmount:discountAmount,
          productAmountAfterDiscount:productAmountAfterDiscount,
          taxedAmount:taxedAmount,
          productAmount:productAmountAfterDiscount,
          finalProductAmount:finalProductAmount,
          netRate:netRate
        }
      })
    }
  },[selectedProduct.price,selectedProduct.quantity,selectedProduct.discountPercentage,selectedProduct.taxPercentage])
  return (
    <Paper className='meds_entry'>
      <Box component={'form'} 
        onSubmit={handleSubmit(addProduct)} 
      >
      <Table size='small' >
        <TableHead>
          <TableRow>
            <TableCell width={'35%'}>name</TableCell>
            <TableCell width={'10%'}>Batch</TableCell>
            <TableCell width={'6%'}>Price</TableCell>
            {/* <TableCell width={'6%'}>B.Rate</TableCell> */}
            {/* <TableCell width={'7%'}>Scheme</TableCell> */}
            <TableCell width={'6%'}>quantity</TableCell>
            <TableCell width={'6%'}>Discount(%)</TableCell>
            <TableCell width={'6%'}>GST</TableCell>
            <TableCell width={'7%'}>Expiry</TableCell>
            <TableCell width={'7%'}>Amount</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        
          <TableRow sx={{p:'0'}}>
            <TableCell  sx={{padding:'1px 1px',textAlign:'center',display:'flex'}} >
              {/* <SelectMedicine clearErrors={clearErrors}/> */}
              <TextField
              fullWidth
                size='small'
                id='productName'
                name='productName'
                value={selectedProduct.productName}
                onChange={inputChangeHandler}
                InputProps={{
                  inputProps:{
                    style:{textTransform:'upperCase'},
                    ...register('productName',{
                      required:'Empty Not Allowed',
                      pattern:{
                          value:/^[a-zA-Z0-9 .$&+,:;=?@#|'<>.^*()%!-]*$/,
                          message:"only alphabets allowed"
                      },
                      minLength:{
                          value:5,
                          message:"minimum 5 digit"
                      },
                      maxLength:{
                          value:50,
                          message:"maximum 50 digit"
                      }
                    })
                  }
                }}
                error={errors.productName}
                // helperText={errors.productName&&errors.productName?.message}
              />
            </TableCell>
            
            <TableCell  sx={{padding:'1px 1px',textAlign:'center'}}>
              <TextField
                size='small'
                id='productBatch'
                name='productBatch'
                value={selectedProduct.productBatch}
                onChange={inputChangeHandler}
                InputProps={{
                  inputProps:{
                    style:{textTransform:'upperCase'},
                    ...register('productBatch',{
                      pattern:{
                        value:/^[a-zA-Z0-9 .]*$/,
                        message:"only alphabets allowed"
                    },
                    })
                  }
                }}
              />

            </TableCell>   
            <TableCell  sx={{padding:'1px 1px',textAlign:'center'}}>
              <TextField
                size='small'
                type='number'
                id='price'
                name='price'
                value={selectedProduct.price}
                onChange={inputChangeHandler}
                InputProps={{
                  inputProps:{
                    ...register('price',{
                      required:'Empty Not Allowed',
                      // min:{
                      //     value:0.01,
                      //     message:"iess than 0.01 not allowed"
                      // }
                    })
                  }
                }}
                error={errors.price}
              />

            </TableCell>  
            {/* <TableCell  sx={{padding:'1px 1px',textAlign:'center'}}>
              <TextField
                // disabled={selectedProduct.medsBatch ? false:true}
                size='small'
                type='number'
                id='baseRate'
                name='baseRate'
                // value={selectedProduct.baseRate}
                placeholder='0.0'
                // onChange={baseRateChangeHandler}
                InputProps={{
                  inputProps:{
                    // ...register(
                    //   'baseRate',{
                    //     max:{value:selectedProduct.mrp},
                    //     required:selectedProduct.baseRate?false:true
                    //   }
                    // )
                  }
                }}
                // error={errors.baseRate}
                // helperText={errors.baseRate && errors.baseRate?.message}
              />
            </TableCell>   */}
            {/* <TableCell  sx={{padding:'1px 1px',textAlign:'center'}}>
              <Box sx={{padding:'1px 1px',border:'1px solid lightGrey',borderRadius:'4px',height:'2.3rem'}} display={'flex'}>
                <TextField
                  // disabled={selectedProduct.medsBatch ? false:true}
                  size='small'
                  type='number'
                  id='schemeA'
                  name='schemeA'
                  // value={selectedProduct.schemeA}
                  // onChangconst{register,handleSubmit,formState:{errors},clearErrors}= useForm();e={inputChangeHandler}
                  InputProps={{
                    style:{height:'2rem'},
                    inputProps:{ min: 1 }
                  }}
                />
                <Box><h3>+</h3></Box>
                <TextField
                  // disabled={selectedProduct.medsBatch ? false:true}
                  size='small'
                  type='number'
                  id='schemeB'
                  name='schemeB'
                  // value={selectedProduct.schemeB}
                  // onChange={inputChangeHandler}
                  InputProps={{
                    style:{height:'2rem'},
                    inputProps:{ min: 0 }
                  }}
                />
              </Box>
            </TableCell>   */}
            <TableCell  sx={{padding:'1px 1px',textAlign:'center'}}>
              <TextField
                  // disabled={selectedProduct.medsBatch ? false:true}
                  size='small'
                  type='number'
                  // step="any"
                  id='quantity'
                  name='quantity'
                  value={selectedProduct.quantity}
                  onChange={inputChangeHandler}
                  InputProps={{
                    inputProps:{ 
                      ...register(
                        'quantity',{
                          required:'required',
                          min:{value:1},
                          // max:{value:selectedProduct.netStock},
                        }
                      )
                     }
                  }}
                  error={errors.quantity}
                // helperText={errors.quantity && errors.quantity?.message}
                />
            </TableCell>  
            <TableCell  sx={{padding:'1px 1px',textAlign:'center'}}>
              <TextField
                // disabled={selectedProduct.medsBatch ? false:true}
                size='small'
                type='number'
                step="any"
                id='discountPercentage'
                name='discountPercentage'
                value={selectedProduct.discountPercentage}
                onChange={inputChangeHandler}
                InputProps={{
                  // endAdornment:<InputAdornment position='end'>%</InputAdornment>,
                  inputProps:{ 
                    ...register(
                      'discountPercentage',{
                         min:{
                          value:0.00,
                        }
                      }
                    )
                  }
                }}
                error={errors.discountPercentage}
              />
            </TableCell>  
            <TableCell  sx={{padding:'1px 1px',textAlign:'center'}}>
              <FormControl fullWidth>
                <Select
                  // disabled={selectedProduct.medsBatch ? false:true}
                  sx={{minHeight:'0px',height:'2.3rem'}}
                  id='taxPercentage'
                  name='taxPercentage'
                  value={selectedProduct.taxPercentage}
                  onChange={inputChangeHandler}
                  // inputProps={{
                  //   ...register('taxPercentage',{
                  //         required:'required'
                  //     }
                  //   )
                  // }}
                  // error={errors.taxPercentage}
                >
                <MenuItem value='0' >Exempt</MenuItem>
                <MenuItem value='5' >GST5%</MenuItem>
                <MenuItem value='12' >GST12%</MenuItem>
                <MenuItem value='18' >GST18%</MenuItem>
                <MenuItem value='28' >GST28%</MenuItem>
                
                </Select>
                
              </FormControl>
            </TableCell>  
            <TableCell  sx={{padding:'1px 1px',textAlign:'center'}}>
              
              <Box className= "customDatePickerWidth">
                <DatePicker
                  disableCalendar
                  clearIcon={null}
                  id='expiryDate'
                  name='expiryDate'
                  value={selectedProduct.expiryDate}
                  onChange={expiryDateChangeHandler}
                  minDate={new Date(new Date().setDate(new Date().getDate()+90))}
                  monthPlaceholder='MM'
                  yearPlaceholder='YYYY'
                  format="MM/y"
                  error
                />   
              </Box>
            </TableCell>  
            <TableCell  sx={{padding:'1px 1px',textAlign:'center'}}>
              <TextField
                size='small'
                type='number'
                disabled
                id='finalProductAmount'
                name='finalProductAmount'
                value={parseFloat(selectedProduct.finalProductAmount).toFixed(2)}
              />
            </TableCell>
            <TableCell  sx={{padding:'1px 1px',textAlign:'center'}}>
              {/* {selectedProduct.medsBatch &&  */}
                <Tooltip title='Add' sx={{cursor:'pointer'}}><IconButton color='success' type='submit'><AddIcon/></IconButton></Tooltip>
                {/*  }*/}
            </TableCell>
          </TableRow>      
        </TableBody>
      </Table>
      </Box>
      <AddedMeds/>
      {/* {errorDialog && <ErrorDialog popup ={errorDialog} errorMessage={errorMessage} onCloseDialog={onCloseExistingMedsBatchErrorDialog}/>} */}
      {/* {netRateError && <NetErrorDialog popup ={netRateError} errorMessage={errorMessage}
       onCloseDialog={onCloseNetRateErrorDialog} 
       ignoreError={onIgnoreNetRateErrorDialog}
       />} */}
    </Paper>
  )
}

export default MedsEntry
